import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Homare = () => {
  return (
    <Layout>
      <Head title="Homare Ikeda" />
      <h3>Homare Ikeda</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Denver, CO
      </h4>
      <p>
        <OutboundLink href="https://www.homareikeda.com/">Website</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15370042/ello-optimized-42c1fca6.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/12567179/ello-optimized-d6811bbc.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15384558/ello-optimized-6624ea17.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/10337463/ello-optimized-b32c42c0.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/10752989/ello-optimized-44828013.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/10632492/ello-optimized-eab56a26.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/13975223/ello-optimized-d52f6abc.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/12783406/ello-optimized-52bea97d.jpg"
        alt="Homare Ikeda art"
        loading="lazy"
      />
    </Layout>
  );
};

export default Homare;
